body {
  margin: 0;
  background-image: url('./assets/Background.svg');
  background-size: cover;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/Inter.ttf) format('ttf');
}
